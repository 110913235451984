<template>
  <div style="height: 100vh;">
    <q-dialog v-model="confirm" persistent>
      <q-card class="q-py-md q-px-sm">
        <q-card-section>Apakah Anda akan keluar dari halaman survey?</q-card-section>
        <q-card-section class="row justify-evenly">
          <q-btn
            dense
            outline
            color="indigo-9"
            style="width: 80px;"
            no-caps
            @click="confirm = false"
            label="Tidak"
          />
          <q-btn
            dense
            unelevated
            color="indigo-9"
            style="width: 80px;"
            no-caps
            @click="closeSurvey"
            label="Ya"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
    <div class="column no-wrap justify-between" style="height: 100%;">
      <div class="row items-center absolute-top-right q-mr-md">
        <div class="text-grey q-pr-xs">{{surveyor.nama}}</div>|
        <q-btn dense flat @click="confirm = true" color="red" label="logout" no-caps />
      </div>
      <div class="col-auto top q-pa-md q-pt-xl">
        <div>Survey Mandiri Tenaga Pendidik</div>
        <div
          style="color: grey; font-size: 0.8em;"
        >checklist bila anda mengalami kondisi ini dalam 7 hari terakhir</div>
      </div>
      <div class="col-auto middle">
        <div class="q-py-md">
          <q-checkbox
            v-model="survey.Q1"
            label="Berkunjung ke tempat umum (pasar, fasyankes, kerumunan, dll) "
          />
        </div>
        <div class="q-py-md">
          <q-checkbox v-model="survey.Q2" label="Menggunakan transportasi umum " />
        </div>
        <div class="q-py-md">
          <q-checkbox
            v-model="survey.Q3"
            label="Melakukan perjalanan luar kota/internasional(zona merah)"
          />
        </div>
        <div class="q-py-md">
          <q-checkbox v-model="survey.Q4" label="Mengikuti kegiatan yang melibatkan orang banyak" />
        </div>
        <div class="q-py-md">
          <q-checkbox
            v-model="survey.Q5"
            label="Kontak erat dengan ODP, PDP atau konfirm COVID-19(jabat tangan, berbicara, berada dalam 1 ruangan)"
          />
        </div>
        <div class="q-py-md">
          <q-checkbox
            v-model="survey.Q6"
            label="Mengalami minimal salah satu dari demam, batuk, pilek, sakit tenggorokan, sesak, hilang penciuman, hilang perasa"
          />
        </div>
        <div class="q-py-md">
          <q-checkbox
            v-model="survey.Q7"
            label="Ada orang serumah yang berinteraksi dengan ODP, PDP atau konfirm COVID-19"
          />
        </div>
        <div class="q-py-md">
          <q-checkbox v-model="survey.Q8" label="Tidak menggunakan masker saat keluar rumah" />
        </div>
      </div>
      <div class="col-2 bottom row items-end">
        <div class="row" style="width: 100%;">
          <q-btn
            class="col-12 q-pa-sm no-border-radius"
            label="Next"
            color="indigo-10"
            @click="onSubmit()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
export default {
  name: 'App',

  data () {
    return {
      confirm: false,
      surveyor: {},
      step: 1,
      survey: {Q1: false, Q2: false, Q3: false, Q4: false, Q5: false, Q6: false, Q7: false, Q8: false},
      lastSurvey: null,
      lastKajian: null,
    }
  },
  mounted(){
    this.$http.get('/kode_unik', {})
      .then (result => {
        if(localStorage.getItem('kode_unik') != result.data.kode_unik){
          this.$router.push('/')
        }
      })
    this.$http.get('/guru_by_noinduk/' + localStorage.getItem('surveyor'), {})
      .then (result => {
          this.surveyor =  result.data
      })
    //cek apakah sudah survey dalam periode ini
    this.$http.get('/validasi_survey_guru/' + localStorage.getItem('surveyor'), {})
      .then (result => {
      
          this.$router.push('/hasil-survey-kajian')
      })
      .catch(err => {
        console.log(err)
      })
      
  },
  methods:{
    closeSurvey(){
      localStorage.removeItem('kode_unik')
      localStorage.removeItem('surveyor')
      this.$router.push('/')
    },
    onSubmit(){
      Vue.prototype.$SURVEY = this.survey
      this.$router.push('/hadir-kajian')
    }

  },
  computed:{
    
  }
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Montserrat";
  src: url("~@/assets/fonts/Montserrat-Medium.otf") format("truetype");
}

* {
  font-family: "Montserrat";
}

.top {
  // min-height: 100px;
  // padding: 5px 18px;
  // overflow-y: auto;
}
.middle {
  background-color: rgb(230, 230, 230);
  // padding: 10px;
  overflow: scroll;
  flex-grow: 99;
}
.bottom {
  // height: 100px;
}

.custom-area {
  width: 90%;
  height: 220px;
  border-radius: 3px;
  padding: 8px;
}

.custom-info pre {
  width: 180px;
  font-size: 12px;
}
</style>